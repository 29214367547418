<template>
  <div class="article">
    <transition name="slide-fade-1">
      <div class="tbl_wrap" key="tbl" v-if="selectedIndex == -1">
        <div class="tbl_option">
          <div>
            <h5>발주 현황</h5>
            <div class="input_checkbox">
              <label
                for="checkbox1"
                class="chk_box"
                :class="check_only_not_completed == true ? 'active' : '_'"
              >
                <i class="fa fa-check"></i>
              </label>
              <input type="checkbox" id="checkbox1" @click="toggleCheckBox()" />
              <label for="checkbox1" class="label_txt"
                >입고대기 항목만 검색</label
              >
            </div>

            <order-print-modal
              v-if="isOrderModalOpen"
              :startDate="start_date"
              :endDate="end_date"
              :checkOption="check_only_not_completed"
              @onclose="closeModal"
            ></order-print-modal>
            <div
              class="modal_layer"
              v-if="isOrderModalOpen"
              @click="closeModal"
            ></div>
          </div>
          <div class="tbl_opt">
            <span>입고 요청일</span>
            <div>
              <div class="input_text">
                <input
                  type="date"
                  placeholder="YYYY-MM-DD"
                  id="start_date"
                  :value="start_date"
                  @change="setStartDate($event)"
                  v-if="showSearchDate"
                />
              </div>
              <p>~</p>
              <div class="input_text">
                <input
                  type="date"
                  placeholder="YYYY-MM-DD"
                  id="end_date"
                  @change="setEndDate($event)"
                  :value="end_date"
                  v-if="showSearchDate"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="mes_tbl_wrap order_print_table">
          <table class="mes_tbl">
            <colgroup>
              <col v-for="(n, index) in 6" :key="index" />
            </colgroup>
            <thead>
              <tr>
                <th>구매처명</th>
                <th>원자재명</th>
                <th>입고 요청일</th>
                <th>발주일</th>
                <th>입고여부</th>
                <th>구매액</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(purchase, index) in filteredPurchaseList"
                :key="purchase.id"
                @click="selectRow(index)"
                :class="{
                  active: selectedIndex == index,
                  new:
                    purchase.purchase_type_id == draft_purchases_type_id[0].id,
                }"
              >
                <td class="text_left">
                  {{
                    `${getCompanyInfo(purchase.company_id).name} ${
                      purchase.purchase_type_id == draft_purchases_type_id[0].id
                        ? ' (임시)'
                        : ''
                    }` || ''
                  }}
                </td>
                <td class="text_left">{{ getFirstPurchaseMaterial(index) }}</td>
                <td>{{ purchase.incoming_date }}</td>
                <td>{{ purchase.input_date }}</td>
                <td>{{ purchase.completed ? 'Y' : 'N' }}</td>
                <td class="text_right">&#8361; {{ getSaleCost(index) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </transition>
    <transition name="slide-fade">
      <div class="aside_management_mode" key="aside" v-if="selectedIndex != -1">
        <div class="head">
          <button type="button" class="btn_prev" @click="selectRow(-1)">
            <h5>발주 현황</h5>
          </button>
          <div>
            <!-- <order-detail-print-modal
              v-if="isOrderDetailModalOpen"
              :managementData="managementData"
              :getCompanyName="getCompanyInfo(managementData.company_id).name"
              :getBarcodeNumber="getBarcodeNumber()"
              @onclose="closeDetailModal"
            ></order-detail-print-modal>
            <purchase-form-print-modal
              v-if="isPurchaseFormModalOpen"
              :managementData="managementData"
              :getCompanyId="managementData.company_id"
              @onclose="closePurchaseModal"
            >
            </purchase-form-print-modal> -->
            <div
              class="modal_layer"
              v-if="isOrderDetailModalOpen"
              @click="closeDetailModal"
            ></div>
            <div
              class="modal_layer"
              v-if="isPurchaseFormModalOpen"
              @click="closePurchaseModal"
            ></div>
          </div>
        </div>
        <div class="aside_cont">
          <div class="input_text">
            <label>구매처</label>
            <div class="input_search">
              <input
                type="text"
                :value="getCompanyInfo(managementData.company_id).name || ''"
                readonly
              />
            </div>
          </div>
          <div>
            <p class="warning">
              {{
                managementMode &&
                managementData.vat_id !=
                  getCompanyInfo(managementData.company_id).vat
                  ? getCompanyInfo(managementData.company_id).vat == 1
                    ? '※ 거래처정보 [부가세별도]로 변경되었습니다.'
                    : getCompanyInfo(managementData.company_id).vat == 2
                    ? '※ 거래처정보 [부가세포함]으로 변경되었습니다.'
                    : '※ 거래처정보 [영세율]으로 변경되었습니다.'
                  : ''
              }}
            </p>
            <span>발주 내역</span>
            <div class="mes_tbl_wrap order_detail_print_table">
              <table class="mes_tbl" :class="{ manage: managementMode }">
                <colgroup>
                  <col v-for="(n, index) in 10" :key="index" />
                </colgroup>
                <thead>
                  <tr>
                    <th>원자재명</th>
                    <th>규격</th>
                    <th>과세여부</th>
                    <th>단위</th>
                    <th>수량</th>
                    <th>단가</th>
                    <th>공급가</th>
                    <th>부가세</th>
                    <th>구매액</th>
                    <th>입고여부</th>
                    <!-- <th
                      v-if="
                        managementData.material_list.length > 0 &&
                          managementMode
                      "
                    >
                      삭제
                    </th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(purchase_material, index) in managementMode
                      ? manage_materials
                      : purchase_materials"
                    :key="(purchase_material.id, index)"
                  >
                    <td class="text_left">
                      {{
                        getMaterialInfo(purchase_material.material_id).name ||
                          ''
                      }}
                    </td>
                    <td class="text_left">
                      {{
                        getMaterialInfo(purchase_material.material_id)
                          .standard || ''
                      }}
                    </td>
                    <td>
                      {{
                        getMaterialInfo(purchase_material.material_id).tax !=
                          purchase_material.tax_id && managementMode
                          ? (purchase_material.tax_id == true
                              ? '과세'
                              : '면세') +
                            ' -> ' +
                            (getMaterialInfo(purchase_material.material_id)
                              .tax == true
                              ? '과세'
                              : '면세')
                          : purchase_material.tax_id == true
                          ? '과세'
                          : '면세'
                      }}
                    </td>
                    <td>
                      {{
                        managementMode
                          ? getUnitName(
                              getMaterialInfo(purchase_material.material_id)
                                .incoming_unit_id,
                            )
                          : getUnitName(purchase_material.unit_id) || ''
                      }}
                    </td>
                    <td>
                      <input
                        type="text"
                        inputmode="decimal"
                        :value="$makeComma(purchase_material.quantity)"
                        :disabled="!managementMode"
                        @input="
                          $inputNumber($event, purchase_material, 'quantity')
                        "
                      />
                      <span class="tbl_sub_info">{{
                        managementData.purchase_materials.find(
                          x => x.id == purchase_material.id,
                        ) != undefined
                          ? managementData.purchase_materials.find(
                              x => x.id == purchase_material.id,
                            ).quantity
                          : ''
                      }}</span>
                    </td>
                    <td>
                      <input
                        type="text"
                        inputmode="decimal"
                        :value="$makeComma(purchase_material.cost)"
                        @input="
                          $inputNumberInt($event, purchase_material, 'cost')
                        "
                        :disabled="!managementMode"
                      />
                      <span class="tbl_sub_info">{{
                        managementData.purchase_materials.find(
                          x => x.id == purchase_material.id,
                        ) != undefined
                          ? managementData.purchase_materials.find(
                              x => x.id == purchase_material.id,
                            ).cost
                          : ''
                      }}</span>
                    </td>
                    <td class="text_right">
                      &#8361;
                      {{
                        calSupplyTax(
                          managementMode
                            ? getCompanyInfo(managementData.company_id).vat
                            : managementData.vat_id,
                          managementMode
                            ? getMaterialInfo(purchase_material.material_id).tax
                            : purchase_material.tax_id,
                          $makeNumber(purchase_material.quantity),
                          $makeNumber(purchase_material.cost),
                        ).supply | makeComma
                      }}
                    </td>
                    <td class="text_right">
                      &#8361;
                      {{
                        calSupplyTax(
                          managementMode
                            ? getCompanyInfo(managementData.company_id).vat
                            : managementData.vat_id,
                          managementMode
                            ? getMaterialInfo(purchase_material.material_id).tax
                            : purchase_material.tax_id,
                          $makeNumber(purchase_material.quantity),
                          $makeNumber(purchase_material.cost),
                        ).tax | makeComma
                      }}
                    </td>
                    <td class="text_right">
                      &#8361;
                      {{
                        calSupplyTax(
                          managementMode
                            ? getCompanyInfo(managementData.company_id).vat
                            : managementData.vat_id,
                          managementMode
                            ? getMaterialInfo(purchase_material.material_id).tax
                            : purchase_material.tax_id,
                          $makeNumber(purchase_material.quantity),
                          $makeNumber(purchase_material.cost),
                        ).total | makeComma
                      }}
                    </td>
                    <td>
                      {{ purchase_material.input_yn ? '입고' : '미입고' }}
                    </td>
                    <!-- <td
                      v-if="
                        managementData.material_list.length > 0 &&
                          managementMode
                      "
                      @click="deleteMaterialListItem(index)"
                    >
                      <button class="tbl_delete_btn">
                        delete
                      </button>
                    </td> -->
                  </tr>

                  <tr
                    v-show="managementData.company_id != null && managementMode"
                  >
                    <td>
                      <button @click="showMaterialSearch = true">
                        <img
                          src="@/assets/images/icon/icon-floating-search.png"
                        />
                      </button>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <!-- <td
                      v-if="
                        managementData.material_list.length > 0 &&
                          managementMode
                      "
                    ></td> -->
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="date_options">
              <div class="receiving_info">
                <div>
                  <div class="input_text">
                    <div>
                      <p class="warning">
                        {{
                          managementMode
                            ? managementData.purchase_materials.length > 0
                              ? '₩ ' +
                                calTotalPrice(
                                  managementData.purchase_materials,
                                  managementData.vat_id,
                                  false,
                                ).tax_total_cost
                              : ''
                            : ''
                        }}
                      </p>
                    </div>
                    <label>과세</label>
                    <input
                      type="text"
                      placeholder=""
                      readonly
                      :value="
                        managementMode
                          ? managementData.material_list.length > 0
                            ? '₩ ' +
                              calTotalPrice(
                                managementData.material_list,
                                getCompanyInfo(managementData.company_id).vat,
                                true,
                              ).tax_total_cost
                            : ''
                          : managementData.purchase_materials.length > 0
                          ? '₩ ' +
                            calTotalPrice(
                              managementData.purchase_materials,
                              managementData.vat_id,
                              false,
                            ).tax_total_cost
                          : ''
                      "
                    />
                  </div>
                  <div class="input_text">
                    <div>
                      <p class="warning">
                        {{
                          managementMode
                            ? managementData.purchase_materials.length > 0
                              ? '₩ ' +
                                calTotalPrice(
                                  managementData.purchase_materials,
                                  managementData.vat_id,
                                  false,
                                ).non_tax_total_cost
                              : ''
                            : ''
                        }}
                      </p>
                    </div>
                    <label>비과세</label>
                    <input
                      type="text"
                      placeholder=""
                      readonly
                      :value="
                        managementMode
                          ? managementData.material_list.length > 0
                            ? '₩ ' +
                              calTotalPrice(
                                managementData.material_list,
                                getCompanyInfo(managementData.company_id).vat,
                                true,
                              ).non_tax_total_cost
                            : ''
                          : managementData.purchase_materials.length > 0
                          ? '₩ ' +
                            calTotalPrice(
                              managementData.purchase_materials,
                              managementData.vat_id,
                              false,
                            ).non_tax_total_cost
                          : ''
                      "
                    />
                  </div>
                </div>
                <div>
                  <div class="input_text">
                    <div>
                      <p class="warning">
                        {{
                          managementMode
                            ? managementData.purchase_materials.length > 0
                              ? '₩ ' +
                                calTotalPrice(
                                  managementData.purchase_materials,
                                  managementData.vat_id,
                                  false,
                                ).total_cost
                              : ''
                            : ''
                        }}
                      </p>
                    </div>
                    <label>구매액</label>
                    <input
                      id="purchases_cost"
                      type="text"
                      placeholder=""
                      readonly
                      :value="
                        managementMode
                          ? managementData.material_list.length > 0
                            ? '₩ ' +
                              calTotalPrice(
                                managementData.material_list,
                                getCompanyInfo(managementData.company_id).vat,
                                true,
                              ).total_cost
                            : ''
                          : managementData.purchase_materials.length > 0
                          ? '₩ ' +
                            calTotalPrice(
                              managementData.purchase_materials,
                              managementData.vat_id,
                              false,
                            ).total_cost
                          : ''
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="date_set">
                <div class="input_text">
                  <label>입고요청일</label>
                  <input
                    type="date"
                    id="setDate"
                    min=""
                    placeholder=""
                    :value="managementData.incoming_date"
                    @change="setIncomingDate($event)"
                    :readonly="!managementMode"
                  />
                </div>
                <div class="input_text">
                  <label>발주일</label>
                  <input
                    type="date"
                    id="today"
                    placeholder=""
                    :value="managementData.input_date"
                    @change="setInputDate($event)"
                    :readonly="!managementMode"
                  />
                </div>
              </div>
              <div class="input_text">
                <label>입고 완료여부</label>
                <input
                  type="text"
                  disabled
                  :value="managementData.completed == true ? 'Y' : 'N'"
                />
              </div>
              <div class="text_set">
                <div class="input_text">
                  <label>비고</label>
                  <textarea
                    :disabled="!managementMode"
                    type="text"
                    placeholder="발주서 내용"
                    v-model="managementData.detail"
                  ></textarea>
                </div>
              </div>
              <div class="btn_wrap">
                <button
                  class="btn_sub2"
                  @click="submitForm"
                  v-show="managementMode"
                  :disabled="isValidModify"
                >
                  수정
                </button>
              </div>
            </div>
            <resource-search
              v-if="showMaterialSearch"
              :filter_type="1"
              @onclose="showMaterialSearch = false"
              @onselect="selectMaterial($event)"
            ></resource-search>
            <!-- <order-print-modal @@@@
              v-show="isOrderModalOpen"
              :startDate="start_date"
              :endDate="end_date"
              :checkOption="check_only_not_completed"
              @onclose="closeModal"
            ></order-print-modal>
            <div
              class="modal_layer"
              v-if="isOrderModalOpen"
              @click="closeModal"
            ></div> -->
          </div>
        </div>
      </div>
    </transition>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="CloseModal"
      @OnYesClick="deletePurchase($event)"
    ></two-button-modal>
    <!-- <barcode-purchase-modal
      v-if="isBarcodeModalOpen"
      @onclose="closeBarcodePrint"
      :printData="printData"
    ></barcode-purchase-modal> -->
    <barcode-print-modal
      v-if="isBarcodeModalOpen"
      @onclose="closeBarcodePrint"
      :printList="printData"
      :checkPage="checkPage"
    >
    </barcode-print-modal>
    <div
      class="modal_layer"
      @click="isBarcodeModalOpen = false"
      v-if="isBarcodeModalOpen"
    ></div>
  </div>
</template>

<script>
import FetchMixin from '@/mixins/fetch';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import FavoriteMixin from '@/mixins/favorite';
import ResourceSearch from '@/layouts/components/search-popup/ResourceSearch';
// import MySelectric from '@/layouts/components/MySelectric';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import { makeComma } from '@/utils/filters';
import PriceMixin from '@/mixins/material_price';
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import OrderPrintModal from '@/layouts/components/OrderPrintModal';

// import OrderDetailPrintModal from '@/layouts/components/OrderDetailPrintModal';
// import PurchaseFormPrintModal from '@/layouts/components/PurchaseFormPrintModal';

// import BarcodePurchaseModal from '@/layouts/components/BarcodePurchaseModal';
import BarcodePrintModal from '@/layouts/components/BarcodePrintModal';

export default {
  mixins: [ModalMixin, SpinnerMixin, FetchMixin, PriceMixin, FavoriteMixin],
  components: {
    ResourceSearch,
    TwoButtonModal,
    OrderPrintModal,
    // OrderDetailPrintModal,
    // BarcodePurchaseModal,
    BarcodePrintModal,
    // PurchaseFormPrintModal,
  },
  data() {
    return {
      managementMode: false,
      showMaterialSearch: false,
      showSearchDate: false,
      checkPage: '발주식별표',

      //delete modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,
      // print modal
      isOrderModalOpen: false,

      isPurchaseFormModalOpen: false,
      isOrderDetailModalOpen: false,

      isBarcodeModalOpen: false,
      printData: null,
    };
  },
  computed: {
    ...mapGetters({
      order_purchases_type_id: 'getOrderPurchaseType',
      draft_purchases_type_id: 'getDraftPurchaseType',
      managementData: 'getManagementDataFromPurchase',
      cloneData: 'getCloneDataFromPurchase',
      manage_materials: 'getManagementDataMaterialListFromPurchase',
      purchase_materials: 'getManagementDataPurchaseMaterialsFromPurchase',
      companys: 'getCompany',
      materials: 'getMaterial',
      units: 'getUnitCodes',
      date: 'getDateFromPurchase',
      purchases: 'getPurchase',
      start_date: 'getStartDateFromPurchase',
      end_date: 'getEndDateFromPurchase',
      selectedIndex: 'getSelectedIndexFromPurchase',
      check_only_not_completed: 'getCheckOnlyNotCompletedFromPurchase',
    }),
    filteredPurchaseList() {
      if (this.purchases.length > 0) {
        return this.lodash
          .clonedeep(this.purchases)
          .sort((a, b) => new Date(b.input_date) - new Date(a.input_date));
      } else return [];
    },
    isValidModify() {
      if (this.managementMode) {
        let modifyData = JSON.stringify(this.managementData);
        let modifyMaterialListLen = this.managementData.material_list.length;

        if (modifyMaterialListLen == 0 || modifyMaterialListLen == undefined)
          return true;

        let originData = JSON.stringify(this.lodash.clonedeep(this.cloneData));

        if (this.selectedIndex != -1) {
          if (modifyData == originData) {
            return true;
          } else return false;
        } else return true;
      } else return true;
    },
    checkTotalCostValid() {
      let calPrice;

      if (this.managementMode) {
        calPrice = this.$makeNumber(
          this.calTotalPrice(
            this.lodash.clonedeep(this.managementData.material_list),
            this.getCompanyInfo(this.managementData.company_id).vat,
            true,
          ).total_cost,
        );
      } else {
        calPrice = this.$makeNumber(
          this.calTotalPrice(
            this.lodash.clonedeep(this.managementData.purchase_materials),
            this.managementData.vat_id,
            false,
          ).total_cost,
        );
      }

      if (calPrice <= 2147483647) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    openBarcodePrint(index) {
      const payload = this.lodash.clonedeep(this.filteredPurchaseList[index]);
      payload.items = this.getFirstPurchaseMaterial(index);
      payload.barcode_num = this.getBarcodeNumber2(payload);

      this.printData = payload;
      this.isBarcodeModalOpen = true;
    },
    closeBarcodePrint() {
      this.isBarcodeModalOpen = false;
    },
    getBarcodeNumber2(payload) {
      const date = payload.input_date.replace(/-/gi, '').substr(2, 6);
      const purchase_id = ('00000' + String(payload.id)).substr(-5);

      return date + purchase_id;
    },
    getBarcodeNumber(item) {
      const date = this.managementData.input_date
        .replace(/-/gi, '')
        .substr(2, 6);
      const purchase_id = ('00000' + String(this.managementData.id)).substr(-5);
      let purchase_material_id = '';
      if (item != undefined) {
        purchase_material_id = ('000000' + String(item.id)).substr(-6);
      }
      return date + purchase_id + purchase_material_id;
    },
    openModal() {
      this.isOrderModalOpen = true;
    },
    closeModal() {
      let orderTableClone = document.querySelector(
        '.order_print_modal .modal_body',
      );
      let orderTable = orderTableClone.querySelector('.order_print_table');
      if (orderTable) {
        orderTable.parentNode.removeChild(orderTable);
      }
      this.isOrderModalOpen = false;
    },
    purchaseModalClose() {
      let orderTableClone = document.querySelector(
        '.order_print_modal .modal_body',
      );
      let orderTable = orderTableClone.querySelector('.order_print_table');
      if (orderTable) {
        orderTable.parentNode.removeChild(orderTable);
      }
      this.isOrderModalOpen = false;
    },
    openDetailModal() {
      this.isOrderDetailModalOpen = true;
    },
    openPurchaseModal() {
      this.isPurchaseFormModalOpen = true;
    },
    closeDetailModal() {
      let orderDetailTableClone = document.querySelector(
        '.order_detail_print_modal .modal_body',
      );
      let orderDetailTable = orderDetailTableClone.querySelector(
        '.order_detail_print_table',
      );
      if (orderDetailTable) {
        orderDetailTable.parentNode.removeChild(orderDetailTable);
      }
      this.isOrderDetailModalOpen = false;
    },
    closePurchaseModal() {
      this.isPurchaseFormModalOpen = false;
    },
    copyData() {
      this.$store.commit(
        'setNewDataToPurchase',
        this.lodash.clonedeep(this.managementData),
      );
      this.$store.commit('setOpenTabIndexToPurchasePage', 1);
      this.selectRow(-1);
    },
    async deletePurchase(arg1) {
      console.log(arg1);
      if (arg1 == undefined) return;
      this.validData()
        .then(() => {
          this.showSpinner();
          //발주 삭제
          console.log(this.managementData);
          this.$store
            .dispatch('DELETE_PURCHASE', this.managementData.id)
            .then(() => {
              this.FETCH_PURCHASE();
              this.selectRow(-1);
            })
            .catch(() => {
              this.openOneButtonModal('삭제 오류', '삭제 중 오류 발생.');
            })
            .finally(() => {
              this.hideSpinner();
              this.CloseModal();
            });
        })
        .finally(() => {
          this.my_isModalOpen = false;
          return;
        });
    },
    ShowModal(index) {
      this.my_modal_index = index;
      this.my_modal_title = '경고';
      this.my_modal_content = '정말로 삭제하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    deleteMaterialListItem(index) {
      this.$store.commit('deleteManagementDataMaterialListItem', index);
    },
    selectRow(index) {
      this.$store.commit('setSelectedIndexToPurchase', index);
      if (index != -1) {
        console.log(this.lodash.clonedeep(this.filteredPurchaseList[index]));
        this.$store.commit(
          'setManagementDataToPurchase',
          this.lodash.clonedeep(this.filteredPurchaseList[index]),
        );
        this.$store.commit(
          'setCloneDataToPurchase',
          this.lodash.clonedeep(this.managementData),
        );
      }
    },
    getSaleCost(index) {
      let purchase_cost = 0;
      this.filteredPurchaseList[index].material_list.forEach(el => {
        purchase_cost += el.total_cost;
      });

      return makeComma(purchase_cost);
    },
    getFirstPurchaseMaterial(index) {
      if (this.filteredPurchaseList[index].material_list[0] != undefined) {
        const firstMaterial = this.getMaterialInfo(
          this.filteredPurchaseList[index].material_list[0].material_id,
        ).name;
        return this.filteredPurchaseList[index].material_list.length > 1
          ? firstMaterial +
              ' 외 ' +
              Number(
                this.filteredPurchaseList[index].material_list.length - 1,
              ) +
              '개 원자재'
          : firstMaterial;
      } else {
        return '';
      }
    },
    async setStartDate(e) {
      if (e.target.value != '') {
        // this.$store.commit('setStartDateToPurchase', e.target.value);
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '입고요청일 오류',
            `입고요청일 최후 날짜(${this.end_date}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit('setStartDateToPurchase', e.target.value);
          this.selectRow(-1);
          await this.FETCH_PURCHASE();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setStartDateToPurchase', yyyymmdd(new Date()));
      }
    },
    async setEndDate(e) {
      if (e.target.value != '') {
        // this.$store.commit('setEndDateToPurchase', e.target.value);
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '입고요청일 오류',
            `입고요청일 최초 날짜(${this.start_date}) 이전의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setEndDateToPurchase', e.target.value);
          this.selectRow(-1);
          await this.FETCH_PURCHASE();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToPurchase', yyyymmdd(new Date()));
      }
    },

    async setInputDate(e) {
      if (e.target.value != '') {
        // this.$store.commit('setEndDateToSales', e.target.value);
        if (e.target.value > this.managementData.incoming_date) {
          this.openOneButtonModal(
            '발주일 오류',
            `납품요청일(${this.managementData.incoming_date}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.managementData.input_date;
        } else {
          this.managementData.input_date = e.target.value;
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
      }
    },
    async setIncomingDate(e) {
      if (e.target.value != '') {
        // this.$store.commit('setStartDateToSales', e.target.value);
        if (e.target.value < this.managementData.input_date) {
          this.openOneButtonModal(
            '납품요청일 오류',
            `발주일(${this.managementData.input_date}) 이전의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.managementData.incoming_date;
        } else {
          this.managementData.incoming_date = e.target.value;
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
      }
    },

    async toggleCheckBox() {
      this.$store.commit('toggleCheckOnlyNotCompletedToPurchase');
      await this.FETCH_PURCHASE();
    },
    submitForm() {
      this.validData().then(() => {
        const payload = this.lodash.clonedeep(this.managementData);
        payload.vat_id = this.getCompanyInfo(
          this.managementData.company_id,
        ).vat;

        payload.material_list.forEach(el => {
          el.cost = this.$makeNumber(el.cost);
          el.quantity = this.$makeNumber(el.quantity);
          el.tax_id = this.getMaterialInfo(el.material_id).tax;
          el.supply_value = this.calSupplyTax(
            this.getCompanyInfo(this.managementData.company_id).vat,
            this.getMaterialInfo(el.material_id).tax,
            el.quantity,
            el.cost,
          ).supply;
          el.tax = this.calSupplyTax(
            this.getCompanyInfo(this.managementData.company_id).vat,
            this.getMaterialInfo(el.material_id).tax,
            el.quantity,
            el.cost,
          ).tax;
          el.tax = this.calSupplyTax(
            this.getCompanyInfo(this.managementData.company_id).vat,
            this.getMaterialInfo(el.material_id).tax,
            el.quantity,
            el.cost,
          ).tax;
          el.total_cost = this.calSupplyTax(
            this.getCompanyInfo(this.managementData.company_id).vat,
            this.getMaterialInfo(el.material_id).tax,
            el.quantity,
            el.cost,
          ).total;
        });
        payload.purchase_materials = this.lodash.clonedeep(
          payload.material_list,
        );

        payload.purchase_type_id = this.order_purchases_type_id[0].id;
        console.log('payload', payload);
        this.showSpinner();
        this.$store
          .dispatch('UPDATE_PURCHASE_ALL', payload)
          .then(() => {
            this.openOneButtonModal(
              '수정 성공',
              '성공적으로 발주를 수정하였습니다.',
            );
            this.FETCH_PURCHASE();
            // this.managementData.purchase_materials = this.lodash.clonedeep(
            //   this.managementData.material_list,
            // );
            this.selectRow(-1);
          })
          .catch(error => {
            console.log(error);
            this.openOneButtonModal(
              '수정 실패',
              '발주 수정 중 오류가 발생했습니다.',
            );
          })
          .finally(() => {
            this.hideSpinner();
          });
      });
    },
    deleteValid() {
      return new Promise((resolve, reject) => {
        if (this.managementData.completed == true) {
          this.openOneButtonModal(
            '수정&삭제 불가',
            '이미 입고가 완료된 발주는 <br />수정 및 삭제가 불가능합니다.',
          );
          reject();
        }
        this.managementData.material_list.forEach(el => {
          if (el.input_yn == true) {
            this.openOneButtonModal(
              '수정&삭제 불가',
              '이미 입고처리된 자재가 존재함으로<br />수정 및 삭제가 불가능합니다.',
            );
            reject();
          }
        });
        resolve();
      });
    },
    async validData() {
      return new Promise((resolve, reject) => {
        if (this.managementData.completed == true) {
          this.openOneButtonModal(
            '수정&삭제 불가',
            '이미 입고가 완료된 발주는 수정 및 삭제가 불가능합니다.',
          );
          reject();
        }
        this.managementData.material_list.forEach(el => {
          if (el.input_yn == true) {
            this.openOneButtonModal(
              '수정&삭제 불가',
              '이미 입고처리된 자재가 존재함으로<br />수정 및 삭제가 불가능합니다.',
            );
            reject();
          }
        });
        if (
          $('#purchases_cost')[0].value == undefined ||
          $('#purchases_cost')[0].value.length < 1
        ) {
          this.openOneButtonModal(
            '수정 불가',
            '반드시 하나 이상의 발주품목을 등록하여주십시오.',
          );
          reject();
        }
        if (String($('#purchases_cost')[0].value).includes('-')) {
          this.openOneButtonModal(
            '수정 불가',
            '구매액은 최소 0원 이상이어야 합니다.',
          );
          reject();
        }
        let modifyMaterialListLen = this.managementData.material_list.length;
        let chkData = this.managementData.material_list;
        for (let i = 0; i < modifyMaterialListLen; i++) {
          if (chkData[i].quantity == 0) {
            this.openOneButtonModal(
              '수정 불가',
              '수량은 최소 1개 이상이어야 합니다.',
            );
            reject();
          }
        }
        if (!this.checkTotalCostValid) {
          this.openOneButtonModal(
            '구매액 초과',
            '1회 발주 최대 금액은<br />20억 이상 발주할 수 없습니다.',
          );
          reject();
        }
        resolve();
      });
    },
    selectMaterial(arg) {
      try {
        this.managementData.material_list.push({
          material_id: arg.id,
          unit_id: arg.incoming_unit_id,
          standard: arg.standard,
          quantity: 0,
          cost: 0, //단가
          supply_value: 0, //공급가
          tax: 0, //부가세
          total_cost: 0, //합계
          tax_id: arg.tax,
        });
      } catch (e) {
        console.log(e);
      }

      this.showMaterialSearch = false;
    },

    getMaterialInfo(id) {
      let hit = this.materials.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    getCompanyInfo(id) {
      let hit = this.companys.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    getUnitName(id) {
      let hit = this.units.find(x => x.id == id);
      return hit != undefined ? hit.name : '';
    },
    pushNewCompany(arg) {
      this.managementData.company_id = arg.id;
      this.showCompanySearch = false;
    },

    async FETCH_PURCHASE() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_PURCHASE', {
          start_date: this.start_date,
          end_date: this.end_date,
          check_only_not_completed: this.check_only_not_completed,
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '발주 리스트를 불러오는 중 오류발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
  },
  async created() {
    if (
      this.order_purchases_type_id == undefined ||
      this.order_purchases_type_id.length < 1
    ) {
      await this.FETCH('FETCH_PURCHASE_TYPE', '구매 유형');
    }

    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.start_date == null) {
      var date = new Date();
      this.$store.commit('setStartDateToPurchase', yyyymmdd(date));
      var date2 = new Date(date.setDate(date.getDate() + 14));
      this.$store.commit('setEndDateToPurchase', yyyymmdd(date2));
    }
    await this.FETCH_PURCHASE();

    this.showSearchDate = true;

    if (this.managementData.input_date == null) {
      var date3 = new Date();
      this.managementData.input_date = yyyymmdd(date3);
      var date4 = new Date(date.setDate(date.getDate() + 7));
      this.managementData.incoming_date = yyyymmdd(date4);
    }
    this.managementData.purchases_type_id = this.order_purchases_type_id[0].id;
  },
};
</script>

<style>
/* 애니메이션 진입 및 진출은 다른 지속 시간 및  */
/* 타이밍 기능을 사용할 수 있습니다. */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.55s ease-in-out;
}
.slide-fade-enter {
  transform: translateX(100%);
}
.slide-fade-leave-to {
  transform: translateX(100%);
}
</style>
